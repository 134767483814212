import { setDefaultOptions } from 'date-fns'
import { useEffect } from 'react'

import { registerLocale, setDefaultLocale } from '@procsea/design-system'

import {
  useActiveBuyerSubscription,
  useCurrentMarketplace,
  useUser,
} from 'src/contexts/userContext'
import { useCompanyVerticals, useLocale, useSegment } from 'src/hooks'
import { useCurrenciesQuery, useMarketplacesQuery } from 'src/queries/shared'
import { Currency, IdentifyBuyerArgs, UserType } from 'src/types'
import { dateLocales } from 'src/utils'
import * as hotjarService from 'src/utils/tracking/hotjar/hotjar.service'

import useDefaultMarketplaceId from './marketplaces/useDefaultMarketplaceId'
import useCurrentBuyerCompany from './useCurrentBuyerCompany/useCurrentBuyerCompany'

const getCurrency = (currencyCode?: string) => (currencies: Currency[]) =>
  currencies.find(current => current.code === currencyCode)

const useInitializeBuyerDashboard = () => {
  const locale = useLocale()
  const { identify, isEnabled } = useSegment()
  const buyerCompany = useCurrentBuyerCompany()
  const user = useUser()
  const verticals = useCompanyVerticals()
  const defaultMarketplaceId = useDefaultMarketplaceId()
  const buyerSubscription = useActiveBuyerSubscription(defaultMarketplaceId)

  const { data: currency } = useCurrenciesQuery({
    queryOptions: { select: getCurrency(buyerCompany?.currency) },
  })
  const { data: ecosystems = [] } = useMarketplacesQuery()

  const activeMarketplace = useCurrentMarketplace(defaultMarketplaceId)

  const isReady = user && buyerCompany && buyerSubscription && verticals.length > 0
  const marketplaceUrl = `/${locale}/${UserType.BUYER}/marketplace/${defaultMarketplaceId}/`

  useEffect(() => {
    registerLocale(locale, dateLocales[locale])
    setDefaultLocale(locale)
    setDefaultOptions({ locale: dateLocales[locale] })
  }, [])

  useEffect(() => {
    if (
      user &&
      ecosystems.length > 0 &&
      buyerCompany &&
      buyerSubscription &&
      isEnabled &&
      activeMarketplace &&
      currency
    ) {
      if (user.isImpersonate) {
        hotjarService.tagImpersonatedBuyer()
      }

      identify<IdentifyBuyerArgs>(`${user.id}`, {
        activeEcosystemId: activeMarketplace.id,
        activeEcosystemName: activeMarketplace.name,
        actorType: UserType.BUYER,
        companyCategory: buyerCompany.category,
        companyCity: buyerCompany.deliveryAddressLocality,
        companyFirstOrderDate: buyerCompany.firstOrderDate,
        companyId: buyerCompany.id,
        companyName: buyerCompany.name,
        companyOrderNumber: buyerCompany.orderNumber,
        country: buyerCompany.country.name,
        createdAt: user.created,
        currencyId: currency.id,
        ecosystemIds: ecosystems.map(({ id }) => id),
        ecosystemNames: ecosystems.map(({ name }) => name),
        email: user.email,
        firstName: user.firstName,
        impersonatorIsStaff: user.impersonatorIsStaff,
        isDemo: buyerCompany.isDemoCompany,
        isImpersonate: user.isImpersonate,
        language: locale,
        lastName: user.lastName,
      })
    }
  }, [activeMarketplace, user, ecosystems, buyerCompany, buyerSubscription, isEnabled])

  return { isReady, marketplaceUrl }
}

export default useInitializeBuyerDashboard
